/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");
/*=============== VARIABLES CSS ===============*/
:root {
    /*========== Colors ==========*/
    /*Color mode HSL(hue, saturation, lightness)*/
    --body-color: #393937;
    --accent-color: #f48c03;
    --white-color: #fff;
    --box-shadow: 0 0 5px #f49403, 0 0 25px #f47b03, 0 0 50px #f48c03,
    0 0 100px #f46f03;
    /*========== Font and typography ==========*/
    /*.5rem = 8px | 1rem = 16px ...*/
    --body-font: "Montserrat", sans-serif;
    --normal-font-size: 1rem;
    /*========== Font weight ==========*/
    --font-regular: 400;
    --font-semi-bold: 600;
    /*========== z index ==========*/
    --z-tooltip: 10;
    --z-fixed: 100;
}

/*=============== BASE ===============*/
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

ul {
    list-style: none;
}

a.box__link {
    text-decoration: none;
    vertical-align: top;
}

.button-animation {
    position: relative;
    display: inline-block;
    padding: 5px 30px;
    color: var(--accent-color);
    text-transform: uppercase;
    overflow: hidden;
    letter-spacing: 4px;
    transition: 0.5s;
}
.button-animation span {
    position: absolute;
    display: block;
}
.button-animation span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, var(--accent-color));
    /*animation: btn-anim1 1s linear infinite;*/
}
@keyframes btn-anim1 {
    0% {
        left: -100%;
    }
    50%, 100% {
        left: 100%;
    }
}
.button-animation span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, var(--accent-color));
    /*animation: btn-anim2 1s linear infinite;*/
    animation-delay: 0.25s;
}
@keyframes btn-anim2 {
    0% {
        top: -100%;
    }
    50%, 100% {
        top: 100%;
    }
}
.button-animation span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, var(--accent-color));
    /*animation: btn-anim3 1s linear infinite;*/
    animation-delay: 0.5s;
}
@keyframes btn-anim3 {
    0% {
        right: -100%;
    }
    50%, 100% {
        right: 100%;
    }
}
.button-animation span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, var(--accent-color));
    /*animation: btn-anim4 1s linear infinite;*/
    animation-delay: 0.75s;
}
@keyframes btn-anim4 {
    0% {
        bottom: -100%;
    }
    50%, 100% {
        bottom: 100%;
    }
}
.button-animation:hover {
    background-color: var(--accent-color);
    color: var(--white-color);
    border-radius: 5px;
    box-shadow: var(--box-shadow);
}
