.cwi{
    padding: 2px !important;
    margin-left: 1px !important;
    margin-right: 1px !important;
    font-weight: bold !important;
    font-size: 0.8em !important;
    max-width: 17px !important;
    max-height: 17px !important;
    min-width: 17px !important;
    min-height: 17px !important;
    filter: brightness(1.4);
}
